import React from "react";
import styled from "styled-components";
import { colors, layout } from "../style/base";

const StyledTab = styled.div`
    overflow-x: scroll;
    ul {
        display: inline-flex;
        list-style: none;
        padding: 80px 10%;
        margin: 0;
    }
    li {
        font-size: 48px;
        margin-right: 40px;
        color: ${(props) =>
            props.theme.mode === "dark"
                ? colors.dark.divider
                : colors.light.divider};
        cursor: pointer;
    }
    li:last-of-type {
        margin-right: 0px;
    }
    li.active {
        color: ${(props) =>
            props.theme.mode === "dark"
                ? colors.dark.primaryText
                : colors.light.primaryText};
    }
    /* For tablet */
    @media (max-width: ${() => layout.breakpoint_tablet}px) {
        ul {
            padding: 50px 10%;
        }
        li {
            font-size: 30px;
            margin-right: 26px;
        }
    }
    /* For phone */
    @media (max-width: ${() => layout.breakpoint_phone}px) {
        ul {
            padding: 30px 22px;
        }
        li {
            font-size: 20px;
            margin-right: 16px;
        }
    }
`;

const Tab = ({ filters, activeTab, setActiveTab }) => {
    return (
        <StyledTab>
            <ul>{renderTabItem(filters, activeTab, setActiveTab)}</ul>
        </StyledTab>
    );
};

const renderTabItem = (filters, activeTab, setActiveTab) => {
    return filters.map((filter, index) => {
        return (
            <li
                onClick={(e) => setActiveTab(e.target.getAttribute("index"))}
                key={index}
                index={index}
                className={index == activeTab ? "h2 active" : "h2"}
            >
                {filter}
            </li>
        );
    });
};

export default Tab;
