import React from "react";
import styled from "styled-components";

import { layout, colors } from "../style/base";

const StyledWorkCover = styled.div`
    height: calc(100vh - 2 * ${layout.frameWidth_l}px - 80px);
    min-height: 600px;
    max-height: 990px;
    display: flex;
    align-items: center;

    .container {
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 6%;
    }

    .cover-text {
        width: 50%;
    }

    .title {
        margin-bottom: 30px;
    }

    .divider {
        width: 100px;
        height: 1px;
        background-color: ${(props) =>
            props.theme.mode === "dark"
                ? colors.dark.divider
                : colors.light.divider};
        margin: 50px 0;
    }

    .cover-image {
        position: absolute;
        right: 0;
        z-index: -1;

        width: 52%;
        padding-top: 52%;
        background-image: ${(props) =>
            props.theme.mode === "dark"
                ? `url(${props.cover_dark})`
                : `url(${props.cover_light})`};
        background-repeat: no-repeat;
        background-size: 79%;
        background-position: 83% 50%;
    }

    /* For desktop */
    @media (max-width: ${() => layout.breakpoint_desktop}px) {
        height: 540px;
    }

    /* For tablet */
    @media (max-width: ${() => layout.breakpoint_tablet}px) {
        height: auto;
        margin-bottom: 80px;
        .container {
            flex-direction: column;
            margin-bottom: 0;
        }
        .cover-image {
            position: static;
            width: 100%;
            padding-top: 100%;
            background-position: 50% 50%;
        }
        .cover-text {
            margin-top: 50px;
            width: 100%;
        }
        .title {
            font-size: 48px;
        }
    }

    /* For phone */
    @media (max-width: ${() => layout.breakpoint_phone}px) {
        .cover-text {
            margin-top: 16px;
        }
        .title {
            font-size: 36px;
            margin-bottom: 16px;
        }
        .subtitle {
            font-size: 16px;
        }
        .divider {
            margin: 20px 0;
        }
    }
`;

const WorkCover = ({
    title,
    subtitle,
    tags,
    cover_light,
    cover_dark,
    duration,
}) => {
    return (
        <StyledWorkCover cover_light={cover_light} cover_dark={cover_dark}>
            <div className="container">
                <div className="cover-image"></div>
                <div className="cover-text">
                    <div className="title">{title}</div>
                    <div className="subtitle">{subtitle}</div>
                    <div className="divider"></div>
                    <div className="caption work-metadata">
                        {tags} Design · {duration}
                    </div>
                </div>
            </div>
        </StyledWorkCover>
    );
};

export default WorkCover;
