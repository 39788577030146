import React, { useContext, useState } from "react";
import styled from "styled-components";
import { ThemeContext } from "../Store";
import storage from "local-storage-fallback";
import Icon from "./Icon";

const StyledAppearanceToggler = styled.div`
    display: flex;

    label {
        position: relative;
        top: 2px;
    }

    select {
        border: 0;
        outline: none;
        font-family: "Open Sans";
        cursor: pointer;
        background: transparent;
        appearance: none;
        margin-left: 8px;
    }

    .chevron {
        margin-left: -4px;
    }
`;

function getInitialValue() {
    const savedValue = storage.getItem("theme");
    return savedValue ? JSON.parse(savedValue).mode : "auto";
}

const AppearanceToggler = () => {
    // Global State
    const [theme, setTheme] = useContext(ThemeContext);

    // Local State
    const [value, setValue] = useState(getInitialValue);
    return (
        <StyledAppearanceToggler>
            <label>Appearance: </label>
            <select
                onChange={() => {
                    const currentValue = document.getElementById("appearance")
                        .value;
                    setTheme({
                        mode: currentValue,
                    });
                    setValue(() => currentValue);
                }}
                className="p2"
                id="appearance"
                value={value}
            >
                <option value="auto">Auto</option>
                <option value="light">Light</option>
                <option value="dark">Dark</option>
            </select>
            <Icon className="chevron" name="expand_more" />
        </StyledAppearanceToggler>
    );
};

export default AppearanceToggler;
