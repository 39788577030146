import React from "react";
import styled from "styled-components";
import WorkGridItem from "./WorkGridItem";
import { layout } from "../style/base";
import Button from "./Button";
import { HashLink } from "react-router-hash-link";

const StyledWorkGrid = styled.div`
    margin-bottom: 200px;
    .work-grid {
        display: grid;
        grid-template-columns: ${(props) =>
            props.small ? "1fr 1fr 1fr" : "1fr 1fr"};
        grid-gap: ${(props) => (props.small ? "36px 20px" : "60px 72px")};
    }
    .work-grid-button {
        display: ${(props) => (props.noButton ? "none" : "block")};
        margin: auto;
        margin-top: 60px;
    }

    /* For tablet */
    @media (max-width: ${() => layout.breakpoint_tablet}px) {
        .work-grid {
            grid-template-columns: 1fr 1fr;
            grid-gap: 28px 24px;
        }
    }
    /* For phone */
    @media (max-width: ${() => layout.breakpoint_phone}px) {
        .work-grid {
            grid-template-columns: 1fr;
        }
    }
`;

const WorkGrid = ({ small, noButton, workGridData }) => {
    // If no data
    if (workGridData.length < 1) {
        return (
            <div
                style={{ textAlign: "center", margin: "120px 0 200px 0" }}
                className="caption"
            >
                We're working hard to bring more works :)
            </div>
        );
    }

    // If there's data
    return (
        <StyledWorkGrid small={small} noButton={noButton} className="container">
            <div className="work-grid">{renderWorkGridItem(workGridData)}</div>
            <HashLink to="/works#top">
                <Button className="work-grid-button">View All Works</Button>
            </HashLink>
        </StyledWorkGrid>
    );
};

function renderWorkGridItem(data) {
    return data.map((work, index) => {
        return <WorkGridItem key={index} data={work} />;
    });
}

export default WorkGrid;
