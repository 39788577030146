import React from "react";
import styled from "styled-components";
import Markdown from "markdown-to-jsx";
import { kebabCase } from "lodash";

import Icon from "./Icon";
import { layout, colors } from "../style/base";
import mds from "../assets/data/md/index";

const StyledWorkContent = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin-bottom: 150px;

    .main {
        width: 66%;
    }

    .entry {
        display: none;
    }

    img {
        width: 100%;
    }

    .p1 {
        margin: 16px 0;
        color: ${(props) =>
            props.theme.mode === "dark"
                ? "rgba(255,255,255,0.75)"
                : colors.light.primaryText};
    }

    .h2 {
        margin: 32px 0 0 0;
    }

    .h1 {
        margin: 72px 0 0 0;
    }

    .h1:first-of-type {
        margin-top: 0;
    }

    h1::before {
        display: block;
        content: " ";
        margin-top: -80px;
        height: 80px;
        visibility: hidden;
        pointer-events: none;
    }

    .outline {
        width: 24%;
        position: sticky;
        top: 80px;
        align-self: flex-start;
    }

    .outline-item {
        display: block;
        color: ${(props) =>
            props.theme.mode === "dark"
                ? colors.dark.thirdText
                : colors.light.thirdText};
    }

    .outline-item.active {
        color: ${(props) =>
            props.theme.mode === "dark"
                ? colors.dark.primaryText
                : colors.light.primaryText};
    }

    /* For tablet */
    @media (max-width: ${() => layout.breakpoint_tablet}px) {
        flex-direction: column;
        .main {
            width: 100%;
        }
        .outline {
            width: 100%;
            position: static;
        }
        .h1:first-of-type {
            margin-top: 72px;
        }
        .outline-item {
            color: ${(props) =>
                props.theme.mode === "dark"
                    ? "rgba(255,255,255,0.75)"
                    : colors.light.primaryText};
        }
    }
`;

const WorkContent = ({ id }) => {
    const md = mds[id];
    if (md == undefined) {
        return (
            <div
                className="caption"
                style={{ textAlign: "center", padding: "40vh 0" }}
            >
                Cannot find content.
            </div>
        );
    }

    return (
        <StyledWorkContent className="container">
            <div className="outline">
                <Icon name="menu" className="entry" />
                <div className="outline-body">
                    <Outline data={md} />
                </div>
            </div>
            <Markdown className="main" options={markdownOptions}>
                {md}
            </Markdown>
        </StyledWorkContent>
    );
};

class Outline extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: 0,
        };
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    handleScroll() {
        const headers = document.getElementsByClassName("h1");

        // Set active outline item
        for (let i = 0; i < headers.length; i++) {
            const header = headers[i];
            if (window.scrollY > header.offsetTop - 100) {
                this.setState({
                    active: i,
                });
            }
        }
    }

    render() {
        return this.getHeaders().map((header, index) => {
            const idName = kebabCase(header);
            return (
                <a
                    href={"#" + idName}
                    className={
                        this.state.active === index
                            ? "active p1 outline-item"
                            : "p1 outline-item"
                    }
                    key={index}
                >
                    {header}
                </a>
            );
        });
    }

    getHeaders() {
        const { data } = this.props;
        let headers = [];
        const lines = data.match(/(#+.*)|([^!?;.\n]+.)/g).map((v) => v.trim());

        for (let i = 0; i < lines.length; i++) {
            const line = lines[i];
            if (line.substring(0, 2) === "# ") {
                headers.push(line.substring(2));
            }
        }
        return headers;
    }
}

const markdownOptions = {
    overrides: {
        h1: {
            props: {
                className: "h1",
            },
        },
        h2: {
            props: {
                className: "h2",
            },
        },
        h3: {
            props: {
                className: "h3",
            },
        },
        p: {
            props: {
                className: "p1",
            },
        },
        li: {
            props: {
                className: "p1",
            },
        },
    },
};

export default WorkContent;
