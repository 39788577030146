import React, { Component } from "react";
import styled from "styled-components";
import Button from "./Button";
import { layout } from "../style/base";
import { showContactModal } from "../pages/Home";

const StyledCallToAction = styled.div`
    height: calc(100vh - 120px);
    min-height: 480px;
    max-height: 1680px;
    display: flex;
    justify-content: center;
    align-items: center;
    .wrapper {
        width: 90%;
        text-align: center;
    }
    Button {
        margin-top: 50px;
    }
    /* For tablet */
    @media (max-width: ${() => layout.breakpoint_tablet}px) {
        height: calc(100vh - 196px);
        .wrapper {
            width: 100%;
        }
    }
`;

class CallToAction extends Component {
    render() {
        return (
            <StyledCallToAction className="container">
                <div className="wrapper">
                    <div className="subtitle">
                        If you have a website or mobile app idea in mind or you
                        need some advice about product design, feel free to
                        contact me.
                    </div>
                    <Button onClick={showContactModal} primary>
                        Contact Me
                    </Button>
                </div>
            </StyledCallToAction>
        );
    }
}

export default CallToAction;
