import React, { Component } from "react";
import styled from "styled-components";
import { colors } from "../style/base";

const StyledIcon = styled.svg`
    width: 24px;
    height: 24px;
    fill: ${(props) =>
        props.theme.mode === "dark"
            ? colors.dark.primaryText
            : colors.light.primaryText};
`;

class Icon extends Component {
    render() {
        return (
            <StyledIcon
                onClick={this.props.onClick}
                className={this.props.className}
                viewBox="0 0 24 24"
            >
                <path d={icons[this.props.name]}></path>
            </StyledIcon>
        );
    }
}

const icons = {
    menu:
        "M4,18 L20,18 C20.55,18 21,17.55 21,17 C21,16.45 20.55,16 20,16 L4,16 C3.45,16 3,16.45 3,17 C3,17.55 3.45,18 4,18 Z M4,13 L20,13 C20.55,13 21,12.55 21,12 C21,11.45 20.55,11 20,11 L4,11 C3.45,11 3,11.45 3,12 C3,12.55 3.45,13 4,13 Z M3,7 C3,7.55 3.45,8 4,8 L20,8 C20.55,8 21,7.55 21,7 C21,6.45 20.55,6 20,6 L4,6 C3.45,6 3,6.45 3,7 Z",
    favorite:
        "M13.35,20.13 C12.59,20.82 11.42,20.82 10.66,20.12 L10.55,20.02 C5.3,15.27 1.87,12.16 2,8.28 C2.06,6.58 2.93,4.95 4.34,3.99 C6.98,2.19 10.24,3.03 12,5.09 C13.76,3.03 17.02,2.18 19.66,3.99 C21.07,4.95 21.94,6.58 22,8.28 C22.14,12.16 18.7,15.27 13.45,20.04 L13.35,20.13 Z",
    favorite_border:
        "M19.66,3.99 C17.02,2.19 13.76,3.03 12,5.09 C10.24,3.03 6.98,2.18 4.34,3.99 C2.94,4.95 2.06,6.57 2,8.28 C1.86,12.16 5.3,15.27 10.55,20.04 L10.65,20.13 C11.41,20.82 12.58,20.82 13.34,20.12 L13.45,20.02 C18.7,15.26 22.13,12.15 22,8.27 C21.94,6.57 21.06,4.95 19.66,3.99 L19.66,3.99 Z M12.1,18.55 L12,18.65 L11.9,18.55 C7.14,14.24 4,11.39 4,8.5 C4,6.5 5.5,5 7.5,5 C9.04,5 10.54,5.99 11.07,7.36 L12.94,7.36 C13.46,5.99 14.96,5 16.5,5 C18.5,5 20,6.5 20,8.5 C20,11.39 16.86,14.24 12.1,18.55 Z",
    expand_more:
        "M15.88,9.29 L12,13.17 L8.12,9.29 C7.73,8.9 7.1,8.9 6.71,9.29 C6.32,9.68 6.32,10.31 6.71,10.7 L11.3,15.29 C11.69,15.68 12.32,15.68 12.71,15.29 L17.3,10.7 C17.69,10.31 17.69,9.68 17.3,9.29 C16.91,8.91 16.27,8.9 15.88,9.29 L15.88,9.29 Z",
    done:
        "M9,16.2 L5.5,12.7 C5.11,12.31 4.49,12.31 4.1,12.7 C3.71,13.09 3.71,13.71 4.1,14.1 L8.29,18.29 C8.68,18.68 9.31,18.68 9.7,18.29 L20.3,7.7 C20.69,7.31 20.69,6.69 20.3,6.3 C19.91,5.91 19.29,5.91 18.9,6.3 L9,16.2 Z",
    clear:
        "M18.3,5.71 C17.91,5.32 17.28,5.32 16.89,5.71 L12,10.59 L7.11,5.7 C6.72,5.31 6.09,5.31 5.7,5.7 C5.31,6.09 5.31,6.72 5.7,7.11 L10.59,12 L5.7,16.89 C5.31,17.28 5.31,17.91 5.7,18.3 C6.09,18.69 6.72,18.69 7.11,18.3 L12,13.41 L16.89,18.3 C17.28,18.69 17.91,18.69 18.3,18.3 C18.69,17.91 18.69,17.28 18.3,16.89 L13.41,12 L18.3,7.11 C18.68,6.73 18.68,6.09 18.3,5.71 Z",
};

export default Icon;
