import React, { Component } from "react";
import styled from "styled-components";
import { colors, fonts } from "../style/base";

const StyledButton = styled.button`
    font-size: 16px;
    color: ${(props) =>
        (props.primary && props.theme.mode === "dark") ||
        (!props.primary && props.theme.mode !== "dark")
            ? colors.light.foreground
            : colors.dark.foreground};
    font-weight: ${() => fonts.bold};
    line-height: 20px;
    padding: 18px 36px;
    border-radius: 22px;
    background-color: ${(props) =>
        !props.primary
            ? "transparent"
            : props.theme.mode === "dark"
            ? colors.dark.foreground
            : colors.light.foreground};
    border: ${(props) =>
        props.primary
            ? 0
            : props.theme.mode === "dark"
            ? "2px solid " + colors.dark.border
            : "2px solid " + colors.light.border};
    outline: 0;
    cursor: pointer;
    transition: all 0.2s;

    :active {
        transform: scale(0.97);
    }
`;

class Button extends Component {
    render() {
        const { onClick, primary, className } = this.props;
        return (
            <StyledButton
                onClick={onClick}
                primary={primary}
                className={className}
            >
                {this.props.children}
            </StyledButton>
        );
    }
}

export default Button;
