import React from "react";
import styled from "styled-components";
import { colors } from "../style/base";
import ClipboardJS from "clipboard";

const StyledId = styled.div`
    background-color: ${(props) =>
        props.theme.mode === "dark"
            ? colors.dark.thirdBackground
            : colors.light.secondaryBackground};
    padding: 10px 22px;
    border-radius: 100px;
    width: 200px;
    text-align: center;
    box-sizing: border-box;

    cursor: pointer;
`;

const Id = ({ children }) => {
    return (
        <StyledId className="p2 id" data-clipboard-text={children}>
            {children}
        </StyledId>
    );
};

// Copying to the clipboard
let clipboard = new ClipboardJS(".id");

clipboard.on("success", function (e) {
    // console.info("Action:", e.action);
    // console.info("Text:", e.text);
    // console.info("Trigger:", e.trigger);
    e.clearSelection();
    e.trigger.innerHTML = "Copied!";
    setTimeout(() => {
        e.trigger.innerHTML = e.text;
    }, 1000);
});

clipboard.on("error", function (e) {
    console.error("Action:", e.action);
    console.error("Trigger:", e.trigger);
});

export default Id;
