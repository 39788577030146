import React, { Component } from "react";
import styled from "styled-components";
import { colors, layout } from "../style/base";
import { Link } from "react-router-dom";

const StyledWorkSectionItem = styled.div`
    display: flex;
    flex-direction: ${(props) => (props.reverse ? "row-reverse" : "row")};
    align-items: center;
    margin-bottom: 100px;
    position: relative;
    cursor: pointer;
    .section-image {
        width: 54%;
        padding-top: 50%;
        background-color: ${(props) =>
            props.theme.mode === "dark"
                ? colors.dark.secondaryBackground
                : colors.light.secondaryBackground};
        border-radius: 25% 2% 25% 2%;
    }
    .section-text {
        position: absolute;
        width: 48%;
        left: ${(props) => (props.reverse ? "0" : "auto")};
        right: ${(props) => (props.reverse ? "auto" : "0")};
    }
    .p1 {
        margin-top: 10px;
    }
    /* For tablet */
    @media (max-width: ${() => layout.breakpoint_tablet}px) {
        flex-direction: column;
        margin-bottom: 80px;
        .section-image {
            width: 100%;
            margin-bottom: 30px;
            padding-top: 100%;
        }
        .section-text {
            position: static;
            width: 100%;
        }
    }
    /* For phone */
    @media (max-width: ${() => layout.breakpoint_phone}px) {
        .section-image {
        }
    }
`;

class WorkSectionItem extends Component {
    render() {
        const { title, subtitle, _id } = this.props.data;
        return (
            <Link to={`/works/${_id}`}>
                <StyledWorkSectionItem
                    reverse={this.props.reverse}
                    className="container"
                >
                    <div className="section-image"></div>
                    <div className="section-text">
                        <div className="h1">{title}</div>
                        <div className="p1">{subtitle}</div>
                    </div>
                </StyledWorkSectionItem>
            </Link>
        );
    }
}

export default WorkSectionItem;
