import React, { Component } from "react";
import styled from "styled-components";
import AppearanceToggler from "./AppearanceToggler";

const StyledFooter = styled.div`
    height: 80px;
    padding: 0px 66px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .right {
        display: flex;
    }
    .legal {
        margin-left: 40px;
    }
    /* For desktop */
    @media (max-width: 1000px) {
        height: auto;
        flex-direction: column;
        padding: 30px;
        .right {
            flex-direction: column;
            align-items: center;
        }
        .legal {
            margin-left: 0px;
            margin-top: 20px;
            text-align: center;
        }
        .copyright {
            margin-bottom: 20px;
        }
    }
`;

class Footer extends Component {
    render() {
        return (
            <StyledFooter className="p2">
                <div className="copyright">© 2020 Bahtiyar Ahmidi</div>
                <div className="right">
                    <AppearanceToggler />
                    <div className="legal">
                        Shanghai Public Security No.31011502008750
                    </div>
                </div>
            </StyledFooter>
        );
    }
}

export default Footer;
