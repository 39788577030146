import image from "./img/General_Business_Process.png";

export default `# Summary

V1: All in one.

V2: Utero is an app that let you record customer data in the most efficient way, and visualize them so you can make the right decision. This is the creation process of Utero, from defining of the product strategy, all the way to branding.

V3: Utero is a lightweight CRM for small to medium sized teams.

# Product Strategy

Worldwide CRM market has reached $48.2B (in 2018), and is dominated by Salesforce with a 19.5% market share. This really is a big market, but we got to have a unique approach to survive in it.

In a long term, our vision for Utero is that it is an all-in-one solution kit for businesses. But it achieves its future vision in three stages:

## 1. A customer data recording tool you love

This is the aspect that many competitors are less focusing on, yet I think is the key that determines if a salespersons like to use the product or not, so we like to explore is there any possibilities to improve.

At this stage, this will be a free mobile application for individual users. We also consider to make a mobile web version of the product, because we like to increase our exposure and get traffic through platforms like DingTalk, Feishu and WeChat for work.

## 2. A collaboration tool for teams

At this stage, we add collaboration into our tool. People can share data within teams, and managers can visualize team data and make decisions based on this.

Big companies are hard to change their existing systems, so we focus on small to medium sized companies or teams to sell our product for free.

We don't want to lose the simplicity of the product as it expands, so have to give new features a switch to turn off. We have to make a product extendable, and will grow with its user.

As we expose to more and more customer, we have to add many customization features and templates, so that we can solve many problems with less features.

## 3. An all-in-one platform for small to medium sized businesses

Since all-in-one creates efficiency and delicacy, we have to become an all-in-one platform for small to medium sized businesses to get the job done in marketing, sales, accounting and customer service.

# Design Principles

While product strategy tells what we do, design principles tell how we do it. So we defined several design principles according to the product strategy, the market and the characteristics of the target users. These will help us make the right decision during the design process.

## 1. Simple

Chinese companies are not fully digitalized, not to think "SaaS-ified", especially our target sector (small companies), so we have to approach them like a 2C product, make it simple and easy to use. To do so, we have to find the core value of our product and eliminate anything extra.

## 2. Cool

To differentiate in the market, we have to make the product cool and fun. We have to focus on the young users, and not to limit by the aging ones, because same design could be awesome to young users and awful to the aging ones.

# General Business Process

Now we know what we build and how we build it in high level, but we still don't understand the specific work process of each user groups and their existing problems.

Therefore I draw the "General business process" map. All of the information here is from my years of personal experiences designing CRM tools at Teambition.

The map is from a customer's life cycle point of view, explaining each business steps, their participants and specific actions they take.

This map provides us with a big picture to understand the whole thing, and help us define user stories.

[![Image](${image})](${image})

# Stage 1: A customer data recording tool you love

## Ideation

How to make a data recording tool you would love? The rule of thumb I believe is "The creation and the recording of the data should be the same process". Users shouldn't spend extra time to record data.

We walked through each business steps, and come up with several ideas to find the best way to record customer data. We grouped these ideas in different levels:

-   **Automation**: The best way to record data is you don't, the system does it for you. The system should grab any public information automatically.
    -   Based on company's website, we could get website registration information.
    -   Based on company name, we could get company registration information.
    -   Based on company's shareholders' name, we could get company information.
-   **Web Forms**: Let your customer fill in the web form, and the system will generate leads automatically.
    -   When a target customer comes to your website, she get interested in your product and leaves her contact, system should provide functions like "Lead Auto Generation" to create leads for you.
    -   For the small conference holders, system could generate web forms and QR codes, and visitors only have to scan the QR code, fill in the information and get the green approval code, once the staff sees the code, he can allow the visitor to come in.
-   **Communication Channel**: According to our rule of thumb, communication should take its place in the CRM to provide convenience to the users.
    -   Calling: when sales rep calls her customer through our app, the app should record the call and automatically attach the voice file to the given customer. It also should have "Convert to text" functionality, so sales rep can highlight the important parts of the conversation when she's writing a followup note.
    -   Sending Emails: Emails are not been using a lot in the customer communication in China, so this feature doesn't have a high priority. But for the users who do, when they send emails to the customer directly through our app, the app would be able to organize the mails accordingly and provide functions like highlighting, creating calendars and more.
    -   Voice Recognition: The app analyzes the voice and compare them with the voice in the record, so it can guess who is the customer that you're having conversation with.
    -   Natural Language Processing: The system can understand the conversation between you and your customer, and creates tasks or calendars for you.
-   **All-in-one**: Use the information that you've already have.
    -   If user creates "Quote" in the system, she can create "Order" with one click, no need to entering SKU information all over again.
    -   If customer creates order or signed the contract, system will automatically mark the opportunity as "Won".
-   **Customer Behavior**
    -   System could get customer's website browsing information, and generates reports like "Most used functions", "Most visited site", etc.
    -   We can also send and record data through hardware.
-   **Import Data**: Sometimes users will have a Excel documents and want to import information in bulk, the system should provide features to support that.
-   **Fields**
    -   The system should provide many built-in fields, so the user can record structured information.
    -   Also has to provided custom fields to record some extra information, but the best way is to enrich built-in fields.
-   **Interaction Design**
    -   The UI and the interaction design should be understandable and efficient.
    -   We should analyze the quoting scenario, and provides a nice tool to quote.

## Feature Prioritization

Features with high value and high viability:

1. Web forms
2. Import data
3. Customer activity statistics
4. Rules
5. Built-in & custom fields
6. Auto-grab public information

Features with low viability:

-   NLP analysis of the call text.

Features with low value:

-   Select email text and convert it into notes, calendars, etc.

# Branding

## Name

Requirements: It's basically a database to store client data

Candidates:

-   Mitra - Greek word for "womb"
-   Utero - Latin word for "womb"

## Logo & Logotype

## Design Language System`;
