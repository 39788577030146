import pic1Light from '../imgs/philosophy-1-light.svg';
import pic1Dark from '../imgs/philosophy-1-dark.svg';

import pic2Light from '../imgs/philosophy-2-light.svg';
import pic2Dark from '../imgs/philosophy-2-dark.svg';

import pic3Light from '../imgs/philosophy-3-light.svg';
import pic3Dark from '../imgs/philosophy-3-dark.svg';

import pic4Light from '../imgs/philosophy-4-light.svg';
import pic4Dark from '../imgs/philosophy-4-dark.svg';

export default [
    {
        title: ["Less", "Is", "More"],
        body: [
            "A designer knows he has achieved perfection not when there’s nothing left to add, but when there is nothing left to take away.",
            "- Antoine de Saint-Exupéry"
        ],
        pic_light: pic1Light,
        pic_dark: pic1Dark
    },
    {
        title: ["Design", "For", "Scenario"],
        body: [
            "Same thing could look very different in different context and scenarios. We need to define a proper scenario in order to have a better understanding to the fact."
        ],
        pic_light: pic2Light,
        pic_dark: pic2Dark
    },
    {
        title: ["Hierarchy", "Brings", "Clarity"],
        body: [
            "When communicating with visual elements, a proper use of visual hierarchy will bring tons of clarity, efficiency and pleasure in our communication."
        ],
        pic_light: pic3Light,
        pic_dark: pic3Dark
    },
    {
        title: ["limitation", "Creates", "Innovation"],
        body: [
            "To me innovation means exploration. A proper use of limitaion will bring an order in our exploraion process, and let us explore effectively and efficiently."
        ],
        pic_light: pic4Light,
        pic_dark: pic4Dark
    }
];
