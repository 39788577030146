import React, { useState } from "react";
import styled from "styled-components";

import Tab from "../components/Tab";
import WorkGrid from "../components/WorkGrid";

import works from "../assets/data/work_data";

const StyledWorks = styled.div`
    #top {
        position: absolute;
        top: 0;
    }
`;

const getFilters = () => {
    let filters = ["All"];
    for (let i = 0; i < works.length; i++) {
        const workTags = works[i].tags;
        for (let j = 0; j < workTags.length; j++) {
            const tag = workTags[j];
            if (!filters.includes(tag)) {
                filters.push(tag);
            }
        }
    }
    return filters;
};

function Works(props) {
    const [currentFilterIndex, setCurrentFilterIndex] = useState(0);
    return (
        <StyledWorks>
            <div id="top"></div>
            <Tab
                filters={getFilters()}
                activeTab={currentFilterIndex}
                setActiveTab={setCurrentFilterIndex}
            />
            <WorkGrid
                small
                noButton
                workGridData={filterdWorkData(currentFilterIndex)}
            />
        </StyledWorks>
    );
}

const filterdWorkData = (currentFilterIndex) => {
    const filters = getFilters();
    return works.filter((work) => {
        if (currentFilterIndex == 0) {
            return true;
        } else if (work.tags.includes(filters[currentFilterIndex])) {
            return true;
        }
        return false;
    });
};

export default Works;
