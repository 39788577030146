import React, { Component } from "react";
import styled from "styled-components";
import PhilosophyGridItem from "./PhilosophyGridItem";
import { layout } from "../style/base";

import data from "../assets/data/philosophy_data";

const StyledPhilosophyGrid = styled.div`
    .philosophy-text {
        margin-bottom: 60px;
    }
    .philosophy-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 30px;
    }
    .p1 {
        margin-top: 10px;
    }
    /* For tablet */
    @media (max-width: ${() => layout.breakpoint_tablet}px) {
        .philosophy-grid {
            grid-template-columns: 1fr;
        }
    }
`;

class Philosophy extends Component {
    render() {
        return (
            <StyledPhilosophyGrid className="container">
                <div className="philosophy-text">
                    <div className="h1">My Design Philosophy</div>
                    <div className="p1">Simple, but worth remembering</div>
                </div>
                <div className="philosophy-grid">{this.renderItem()}</div>
            </StyledPhilosophyGrid>
        );
    }

    renderItem() {
        return data.map((item, index) => {
            return (
                <PhilosophyGridItem
                    key={index}
                    active={index === 0 ? true : false}
                    data={item}
                />
            );
        });
    }
}

export default Philosophy;
