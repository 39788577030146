import React from "react";
import styled from "styled-components";

import Button from "./Button";
import { showContactModal } from "../pages/Home";
import { layout, colors } from "../style/base";

// Files
import avatarDark from '../assets/imgs/avatar-dark.png';
import avatarLight from '../assets/imgs/avatar-light.png';

const StyledCover = styled.div`
    height: calc(100vh - 2 * ${layout.frameWidth_l}px - 80px);
    min-height: 400px;
    max-height: 990px;
    display: flex;
    align-items: center;

    .container {
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 50px;
    }

    .cover-text {
        width: 56%;
        margin-bottom: 2%;
    }

    .title {
        margin-bottom: 40px;
    }

    .cover-image {
        position: absolute;
        right: 0;
        width: 52%;
        padding-top: 52%;
        border-radius: 25% 2% 25% 2%;
        z-index: -1;
        background-image: ${(props) =>
            props.theme.mode === "dark"
                ? `url(${avatarDark})`
                : `url(${avatarLight})`};
        background-repeat: no-repeat;
        background-size: ${(props) =>
            props.theme.mode === "dark" ? "50%" : "66%"};
        background-color: ${(props) =>
            props.theme.mode === "dark"
                ? colors.light.foreground
                : colors.light.secondaryBackground};
        background-blend-mode: ${(props) =>
            props.theme.mode === "dark" ? "lighten" : "darken"};
        background-position: ${(props) =>
            props.theme.mode === "dark" ? "75% 0%" : "80% 50%"};
    }

    .cover-button {
        margin-top: 50px;
    }

    span {
        box-shadow: inset 0 -20px 0 ${(props) => (props.theme.mode === "dark" ? colors.dark.coloredBackground : colors.light.coloredBackground)};
    }

    /* For desktop */
    @media (max-width: ${() => layout.breakpoint_desktop}px) {
        height: 540px;
    }

    /* For tablet */
    @media (max-width: ${() => layout.breakpoint_tablet}px) {
        height: auto;
        margin-top: 12px;
        .container {
            flex-direction: column;
        }
        .cover-image {
            position: static;
            width: 52%;
            background-position: ${(props) =>
                props.theme.mode === "dark" ? "50% 0%" : "50% 50%"};
        }
        .cover-text {
            margin-top: 50px;
            width: 100%;
        }
        .cover-button {
            width: 100%;
        }
        .title {
            font-size: 48px;
        }
    }

    /* For phone */
    @media (max-width: ${() => layout.breakpoint_phone}px) {
        .cover-image {
            width: 100%;
            padding-top: 100%;
            background-position: 50% 80%;
        }
        .cover-text {
            margin-top: 35px;
        }
        .title {
            font-size: 36px;
            margin-bottom: 10px;
        }
        .subtitle {
            font-size: 16px;
        }
    }
`;

const Cover = () => {
    return (
        <StyledCover>
            <div className="container">
                <div className="cover-image"></div>
                <div className="cover-text">
                    <div className="title">Hi, I'm Bahtiyar</div>
                    <div className="subtitle">
                        I do product design, web and brand design, currently
                        working at <span>Alibaba</span>, based in Shanghai.
                    </div>
                    <Button
                        onClick={showContactModal}
                        primary
                        className="cover-button"
                    >
                        Contact Me
                    </Button>
                </div>
            </div>
        </StyledCover>
    );
};

export default Cover;
