import coverFree1Light from '../imgs/cover_free_light_utero_crm.png';
import coverFree1Dark from '../imgs/cover_free_light_utero_crm.png';

import coverFree2Light from '../imgs/cover_free_light_utero_crm.png';
import coverFree2Dark from '../imgs/cover_free_light_utero_crm.png';

import coverFree3Light from '../imgs/cover_free_light_utero_crm.png';
import coverFree3Dark from '../imgs/cover_free_light_utero_crm.png';

import coverFree4Light from '../imgs/cover_free_light_utero_crm.png';
import coverFree4Dark from '../imgs/cover_free_light_utero_crm.png';

export default [
    {
        _id: "utero-crm",
        title: "Utero CRM",
        subtitle: "An app that let's you record customer data in a most efficient way, and visualize them so you can make the right decision.",
        cover_rect: "url",
        cover_free_light: coverFree1Light,
        cover_free_dark: coverFree1Dark,
        tags: ["Product"],
        duration: "April 9 - September 11",
        position: 0
    },
    {
        _id: "f-timer",
        title: "F-Timer",
        subtitle: "A fitness timer",
        cover_rect: "url",
        cover_free_light: coverFree2Light,
        cover_free_dark: coverFree2Dark,
        tags: ["Product"],
        duration: "April 9 - September 11",
        position: 0
    },
    {
        _id: "go-event",
        title: "GoEvent",
        subtitle: "A meetup app where you can find nearby events instantly.",
        cover_rect: "url",
        cover_free_light: coverFree3Light,
        cover_free_dark: coverFree3Dark,
        tags: ["Product"],
        duration: "April 9 - September 11",
        position: 0
    },
    {
        _id: "teambition-service-dialog-page-redesign",
        title: "Teambition Service Dialog Page Redesign",
        subtitle: "Re-Creating Teambition Service dialog page",
        cover_rect: "url",
        cover_free_light: coverFree4Light,
        cover_free_dark: coverFree4Dark,
        tags: ["Web"],
        duration: "April 9 - September 11",
        position: 1
    }
]
