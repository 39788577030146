import React from "react";
import styled from "styled-components";

import WorkGrid from "../components/WorkGrid";
import works from "../assets/data/work_data";

const StyleNotFound = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0;

    .text {
        text-align: center;
        margin: 80px auto;
    }

    .title {
        margin-bottom: 30px;
    }

    .subtitle{
        word-break: break-all;
    }

    .works {
        margin: 0;
    }
`;

const NotFound = ({ location }) => {
    return (
        <StyleNotFound>
            <div className="container text">
                <div className="title">Hey, buddy</div>
                <div className="subtitle">
                    What do you mean by “{location.pathname.substring(1)}”?
                </div>
                <div className="subtitle">
                    Why don't you check out some of my works.
                </div>
            </div>
            <WorkGrid className="works" noButton workGridData={works} />
        </StyleNotFound>
    );
};

export default NotFound;
