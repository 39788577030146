import React, { Component } from "react";
import styled from "styled-components";
import { colors, fonts, layout } from "../style/base";
import { Link } from "react-router-dom";

const StyledWorkGridItem = styled.div`
    .work-grid-card {
        width: 100%;
        padding-top: 75%;
        background-color: ${(props) =>
            props.theme.mode === "dark"
                ? colors.dark.secondaryBackground
                : colors.light.secondaryBackground};
        border-radius: 10px;
    }
    .work-grid-tag {
        text-transform: uppercase;
        color: ${(props) =>
            props.theme.mode === "dark" ? colors.dark.b50 : colors.light.b50};
        font-size: 16px;
        font-weight: ${() => fonts.semibold};
        line-height: 1.25;
        letter-spacing: -0.2px;

        margin: 12px 0 3px 0;
    }
    .work-grid-title {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    /* For phone */
    @media (max-width: ${() => layout.breakpoint_phone}px) {
        .work-grid-tag {
            font-size: 12px;
        }
    }
`;

class WorkGridItem extends Component {
    render() {
        const { tags, title, _id } = this.props.data;
        return (
            <Link to={`/works/${_id}`}>
                <StyledWorkGridItem>
                    <div className="work-grid-card"></div>
                    <div className="work-grid-tag">{tags}</div>
                    <div className="p1 work-grid-title">{title}</div>
                </StyledWorkGridItem>
            </Link>
        );
    }
}

export default WorkGridItem;
