import React, { useState, createContext, useEffect } from "react";
import storage from "local-storage-fallback";
import { ThemeProvider } from "styled-components";

// Create Context
export const ThemeContext = createContext();

const Store = ({ children }) => {
    // Get browser theme mode
    const browserIsDark = window.matchMedia("(prefers-color-scheme: dark)")
        .matches;

    // Create state
    const [theme, setTheme] = useState(getInitialTheme);

    // Save current theme into local storage
    useEffect(() => {
        storage.setItem("theme", JSON.stringify(theme));
    }, [theme]);

    // Return component
    return (
        <ThemeContext.Provider value={[theme, setTheme]}>
            <ThemeProvider
                theme={
                    theme.mode === "auto"
                        ? browserIsDark
                            ? { mode: "dark" }
                            : { mode: "light" }
                        : theme
                }
            >
                {children}
            </ThemeProvider>
        </ThemeContext.Provider>
    );
};

// Helper Functions
const getInitialTheme = () => {
    const savedTheme = storage.getItem("theme");
    return savedTheme ? JSON.parse(savedTheme) : { mode: "auto" };
};

export default Store;
