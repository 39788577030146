import React from "react";
import styled from "styled-components";
import { colors, layout } from "../style/base";

import AboutImage from '../assets/imgs/about.png';

const StyledAbout = styled.div`
    display: flex;
    justify-content: flex-end;
    .pic {
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;

        width: 36vw;
        height: 100vh;
        min-width: 400px;

        background-color: ${(props) =>
            props.theme.mode === "dark"
                ? colors.dark.secondaryBackground
                : colors.light.secondaryBackground};
        background-image: url(${AboutImage});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
    }

    .content {
        width: 62vw;
        word-wrap: break-word;
        padding: 160px 160px 160px 110px;
        box-sizing: border-box;
        min-height: calc(100vh - 200px);
    }

    .h2 {
        margin: 32px 0 0 0;
    }

    /* For desktop */
    @media (max-width: ${() => layout.breakpoint_desktop}px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        .pic {
            position: static;
            margin-top: -80px;
            width: 100%;
            height: 500px;
            min-width: unset;
        }
        .content {
            width: 100%;
            max-width: 800px;
            padding: 40px 60px;
            height: unset;
        }
    }

    /* For phone */
    @media (max-width: ${() => layout.breakpoint_phone}px) {
        .content {
            padding: 10px 20px;
        }
    }
`;

const About = () => {
    return (
        <StyledAbout>
            <div className="pic"></div>
            <div className="content">
                <p className="p1">
                    Hi, I’m Simon. I believe that equitable access to
                    information and technology creates a more just and inclusive
                    world. I'm skeptical about what is and am determined to have
                    a positive impact by transforming how people consume and
                    comprehend the news?.
                </p>
                <p className="h2">Education</p>
                <p className="p1">
                    2011 - 2015 BA in Biotechnology, Shanghai Jiao Tong
                    Univercity
                </p>
                <p className="h2">Work Experience</p>
                <p className="p1">
                    2016.02 - 2016.08 UX Designer at CuiPin Tech Co., Ltd.
                </p>
                <p className="p1">
                    2017.03 - 2019.04 Product Designer at Teambition
                </p>
                <p className="p1">
                    2019.04 - Present UX Designer at Alibaba Group
                </p>
            </div>
        </StyledAbout>
    );
};

export default About;
