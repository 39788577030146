import { createGlobalStyle } from "styled-components";
import OpenSansRegular from "../assets/fonts/open_sans/OpenSans-Regular.ttf";
import OpenSansSemiBold from "../assets/fonts/open_sans/OpenSans-SemiBold.ttf";
import OpenSansBold from "../assets/fonts/open_sans/OpenSans-Bold.ttf";
import OpenSansExtraBold from "../assets/fonts/open_sans/OpenSans-ExtraBold.ttf";

// -------------- Global Styles -------------- //

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "Open Sans";
    src: url(${OpenSansRegular});
    font-weight: 400;
  }
  @font-face {
    font-family: "Open Sans";
    src: url(${OpenSansSemiBold});
    font-weight: 600;
  }
  @font-face {
    font-family: "Open Sans";
    src: url(${OpenSansBold});
    font-weight: 700;
  }
  @font-face {
    font-family: "Open Sans";
    src: url(${OpenSansExtraBold});
    font-weight: 900;
  }

  body {
    margin: ${() => layout.frameWidth_l}px;
    background: ${(props) =>
        props.theme.mode === "dark"
            ? colors.dark.background
            : colors.light.background};
    font-family: "Open Sans";
    -webkit-font-smoothing: antialiased;
  }

  body.modal-open {
      overflow: hidden;
  }

  a{
    text-decoration: none;
  }

  .caption{
    font-size: 16px;
    color: ${(props) =>
        props.theme.mode === "dark"
            ? colors.dark.secondaryText
            : colors.light.secondaryText};
    line-height: 20px;
    font-weight: ${() => fonts.semibold};
  }

  .p2 {
    font-size: 16px;
    color: ${(props) =>
        props.theme.mode === "dark"
            ? colors.dark.primaryText
            : colors.light.primaryText};
    font-weight: ${() => fonts.semibold};
    line-height: 20px;
    letter-spacing: 0.2px;
  }

  .p1 {
    font-size: 20px;
    color: ${(props) =>
        props.theme.mode === "dark"
            ? colors.dark.primaryText
            : colors.light.primaryText};
    line-height: 1.6;
    font-weight: ${() => fonts.regular};
  }

  .title{
    font-size: 5.2vw;
    color: ${(props) =>
        props.theme.mode === "dark"
            ? colors.dark.primaryText
            : colors.light.primaryText};
    font-weight: ${() => fonts.extrabold};
    line-height: 1.1;
    letter-spacing: -0.15vw;
  }

  .subtitle{
    font-size: 2vw;
    color: ${(props) =>
        props.theme.mode === "dark"
            ? colors.dark.primaryText
            : colors.light.primaryText};
    font-weight: ${() => fonts.semibold};
    line-height: 1.5;
  }

  .h1{
    font-size: 3.2vw;
    color: ${(props) =>
        props.theme.mode === "dark"
            ? colors.dark.primaryText
            : colors.light.primaryText};
    letter-spacing: -1px;
    line-height: 1.4;
    font-weight: ${() => fonts.extrabold};
  }

  .h2{
    font-size: 30px;
    color: ${(props) =>
        props.theme.mode === "dark"
            ? colors.dark.primaryText
            : colors.light.primaryText};
    line-height: 1.4;
    font-weight: ${() => fonts.extrabold};
  }

  .h3 {
    font-size: 20px;
    color: ${(props) =>
        props.theme.mode === "dark"
            ? colors.dark.primaryText
            : colors.light.primaryText};
    line-height: 1.6;
    font-weight: ${() => fonts.extrabold};
  }

  .container{
    width: 80%;
    max-width: 1536px;
    margin-left: auto;
    margin-right: auto;
  }

  ::selection {
    background: ${(props) =>
        props.theme.mode === "dark"
            ? colors.dark.coloredBackground
            : colors.light.coloredBackground};
  }

  /* For desktop */
  @media (max-width: ${() => layout.breakpoint_desktop}px) {
    .h1{
      font-size: 42px;
    }
    .p1{
      font-size: 18px;
    }
  }

  /* For tablet */
  @media (max-width: ${() => layout.breakpoint_tablet}px) {
    .h1{
      font-size: 36px;
    }
    .h2{
      font-size: 24px;
    }
    .title{
      font-size: 40px;
    }
    .subtitle {
      font-size: 24px;
    }
  }

  /* For phone */
  @media (max-width: ${() => layout.breakpoint_phone}px) {
    body{
        margin: ${() => layout.frameWidth_s}px;
    }
    .container{
      width: calc(100vw - 60px);
    }
    .title{
      font-size: 30px;
    }
    .subtitle {
      font-size: 18px;
    }
    .h1{
      font-size: 30px;
    }
    .h2{
      font-size: 22px;
    }
    .p1{
      font-size: 16px;
    }
  }

`;

// --------------- Variables --------------- //

export const colors = {
    light: {
        primary: "#36FFF6",
        background: "#fff",
        secondaryBackground: "#FAFAFA",
        foreground: "#121213",
        coloredBackground: "#36FFF6",
        //
        primaryText: "#121213",
        secondaryText: "rgba(0,0,0,0.5)",
        thirdText: "rgba(0,0,0,0.3)",
        border: "rgba(0,0,0,0.04)",
        divider: "rgba(0,0,0,0.07)",
        //
        b50: "#0077FF",
    },
    dark: {
        primary: "#05FFF4",
        background: "#121213",
        secondaryBackground: "#161617",
        thirdBackground: "#1A1A1B",
        foreground: "#fff",
        coloredBackground: "#00B8B3",
        //
        primaryText: "#fff",
        secondaryText: "rgba(255,255,255,0.5)",
        thirdText: "rgba(255,255,255,0.3)",
        border: "rgba(255,255,255,0.1)",
        divider: "rgba(255,255,255,0.15)",
        //
        b50: "#0077FF",
    },
};

export const shadows = {
    l4: "0 2px 4px 0 rgba(0,0,0,0.03), 0 40px 80px 0 rgba(0,0,0,0.17)",
    d4: "0 2px 4px 0 rgba(0,0,0,0.10)",
};

export const fonts = {
    regular: "400",
    semibold: "600",
    bold: "700",
    extrabold: "900",
};

export const layout = {
    frameWidth_l: 20,
    frameWidth_s: 8,
    breakpoint_phone: 520,
    breakpoint_tablet: 800,
    breakpoint_desktop: 1280,
    breakpoint_hd: 1680,
};
