import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { GlobalStyle } from "./style/base";
import Store from "./Store";

// Pages
import Home from "./pages/Home";
import Works from "./pages/Works";
import About from "./pages/About";
import NotFound from "./pages/NotFound";
import WorkDetail from "./pages/WorkDetail";

// Global Components
import NavBar from "./components/NavBar";
import Frame from "./components/Frame";
import Footer from "./components/Footer";

function App() {
    return (
        <Store>
            <GlobalStyle />
            <Router>
                <Route path="/" component={Frame} />
                <Route path="/" component={NavBar} />
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/works" component={Works} />
                    <Route exact path="/about" component={About} />
                    <Route exact path="/works/:id" component={WorkDetail} />
                    <Route path="*" component={NotFound} />
                </Switch>
                <Route path="/" component={Footer} />
            </Router>
        </Store>
    );
}

export default App;
