import React, { Component } from "react";
import styled from "styled-components";
import { colors, layout } from "../style/base";

const StyledFrame = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    box-shadow: inset 0 0 0 ${layout.frameWidth_l}px
        ${(props) =>
            props.theme.mode === "dark"
                ? colors.dark.primary
                : colors.light.primary};
    z-index: 99;

    /* For phone */
    @media (max-width: ${() => layout.breakpoint_phone}px) {
        box-shadow: inset 0 0 0 ${layout.frameWidth_s}px
            ${(props) =>
                props.theme.mode === "dark"
                    ? colors.dark.primary
                    : colors.light.primary};
    }
`;

class Frame extends Component {
    render() {
        return <StyledFrame></StyledFrame>;
    }
}

export default Frame;
