import React, { useEffect } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

// Custom components
import WorkCover from "../components/WorkCover";
import WorkContent from "../components/WorkContent";

// Data
import works from "../assets/data/work_data";

const StyledWorkDetail = styled.div``;

const WorkDetail = () => {
    const { pathname } = useLocation();
    const currentWorkName = pathname.substring(7);
    const currentWork = works.filter((work) => {
        if (work._id === currentWorkName) {
            return true;
        }
        return false;
    })[0];

    // Scroll to top
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    // Catch error
    if (currentWork == null) {
        return (
            <div
                className="caption"
                style={{ textAlign: "center", padding: "40vh 0" }}
            >
                Cannot find a work called "{currentWorkName}"
            </div>
        );
    }

    const {
        title,
        subtitle,
        tags,
        cover_free_light,
        cover_free_dark,
        duration,
        _id,
    } = currentWork;

    return (
        <StyledWorkDetail>
            <WorkCover
                cover_light={cover_free_light}
                cover_dark={cover_free_dark}
                title={title}
                subtitle={subtitle}
                tags={tags}
                duration={duration}
            />
            <WorkContent id={_id} />
        </StyledWorkDetail>
    );
};

export default WorkDetail;
