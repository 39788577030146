import React, { Component } from "react";
import styled from "styled-components";
import WorkSectionItem from "./WorkSectionItem";

import works from "../assets/data/work_data";

const StyledWorkSection = styled.div`
    margin-bottom: 200px;
`;

class WorkSection extends Component {
    render() {
        return (
            <StyledWorkSection>{this.renderSectionItem()}</StyledWorkSection>
        );
    }

    renderSectionItem() {
        return works.map((work, index) => {
            if (work.position === 0) {
                return (
                    <WorkSectionItem
                        key={index}
                        data={work}
                        reverse={index % 2 === 0 ? false : true}
                    />
                );
            }
        });
    }
}

export default WorkSection;
