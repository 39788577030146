import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { colors, shadows, layout } from "../style/base";
import Icon from "./Icon";
import Id from "./Id";

// Files
import qrDark from "../assets/imgs/qr-dark.jpg";
import qrLight from "../assets/imgs/qr-light.jpg";

const StyledContactModal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;

    .wrapper {
        position: relative;
        background-color: ${(props) =>
            props.theme.mode === "dark"
                ? colors.dark.secondaryBackground
                : colors.light.background};
        width: 720px;
        margin: auto;

        top: 44%;
        margin-top: -250px;

        border-radius: 10px;
        box-shadow: ${(props) =>
            props.theme.mode === "dark" ? shadows.d4 : shadows.l4};
        overflow: scroll;
    }

    .contact-bar {
        display: flex;
        justify-content: flex-end;
        padding: 12px;
    }

    .close-button {
        cursor: pointer;
        padding: 10px;
    }

    .contact-body {
        display: flex;
        justify-content: space-evenly;
        margin-top: 8px;
        margin-bottom: 82px;
    }

    .contact-unit {
        display: flex;
        flex-direction: column;
        align-items: center;
        .img {
            width: 200px;
            height: 200px;
            background-color: ${(props) =>
                props.theme.mode === "dark"
                    ? colors.dark.thirdBackground
                    : colors.light.secondaryBackground};
            margin: 30px 0;
        }
        .qr {
            background-image: ${(props) =>
                props.theme.mode === "dark"
                    ? `url(${qrDark})`
                    : `url(${qrLight})`};
            background-repeat: no-repeat;
            background-size: contain;
        }
        .button {
            line-height: 200px;
            text-align: center;
            cursor: pointer;
        }
    }

    /* For tablet */
    @media (max-width: ${() => layout.breakpoint_tablet}px) {
        .wrapper {
            width: 100vw;
            height: 100vh;
            top: 0;
            border-radius: 0px;
            margin-top: 0px;
        }
    }

    /* For phone */
    @media (max-width: ${() => layout.breakpoint_phone}px) {
        .contact-body {
            flex-direction: column;
        }
        .contact-unit:first-of-type {
            margin-bottom: 80px;
        }
    }
`;

const ContactModal = () => {
    return (
        <StyledContactModal onClick={onBgClick}>
            <div className="wrapper">
                <Content />
            </div>
        </StyledContactModal>
    );
};

const Content = () => {
    return (
        <div className="modal-content">
            <div className="contact-bar">
                <Icon
                    className="close-button"
                    onClick={hideContactModal}
                    name="clear"
                />
            </div>
            <div className="contact-body">
                <div className="contact-unit wechat">
                    <div className="h2">WeChat</div>
                    <div className="img qr"></div>
                    <Id>bahtiyar-ahmidi</Id>
                </div>
                <div className="contact-unit email">
                    <div className="h2">Email</div>
                    <a className="img button p2" href="mailto:ihappy88@126.com">
                        Open Mail App
                    </a>
                    <Id>ihappy88@126.com</Id>
                </div>
            </div>
        </div>
    );
};

function hideContactModal() {
    document.getElementsByTagName("body")[0].classList.remove("modal-open");
    ReactDOM.render(<div></div>, document.querySelector(".contact-modal"));
}

function onBgClick(e) {
    if (e.target === e.currentTarget) {
        hideContactModal();
    }
}

export default ContactModal;
