import React, { Component } from "react";
import styled from "styled-components";
import { layout, colors } from "../style/base";

const StyledPhilosophyGridItem = styled.div`
    cursor: default;
    width: 100%;
    height: 34vw;
    box-sizing: border-box;
    padding: 60px 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: ${(props) =>
        props.active && props.theme.mode !== "dark"
            ? colors.light.foreground
            : props.active && props.theme.mode === "dark"
            ? colors.dark.foreground
            : !props.active && props.theme.mode !== "dark"
            ? colors.light.secondaryBackground
            : colors.dark.secondaryBackground};
    border-radius: 10px;
    :nth-of-type(2) {
        margin-top: 60px;
    }
    :nth-of-type(3) {
        margin-top: -60px;
    }
    .h1,
    .p1 {
        color: ${(props) =>
            (props.active && props.theme.mode !== "dark") ||
            (!props.active && props.theme.mode === "dark")
                ? colors.dark.foreground
                : colors.light.foreground};
    }

    .top {
        display: flex;
        justify-content: space-between;
    }

    .h1 {
        font-size: calc(4.44vw - 12.8px);
        line-height: 1.16;
    }

    .pic {
        width: calc(8.8vw - 25.5px);
        height: calc(8.8vw - 25.5px);
        border-radius: 100%;
        border: solid 3px;
        border-color: ${(props) =>
            (props.active && props.theme.mode !== "dark") ||
            (!props.active && props.theme.mode === "dark")
                ? colors.dark.foreground
                : colors.light.foreground};
        background-image: ${(props) =>
            (props.active && props.theme.mode !== "dark") ||
            (!props.active && props.theme.mode === "dark")
                ? `url(${props.data.pic_dark})`
                : `url(${props.data.pic_light})`};
        background-repeat: no-repeat;
        background-size: contain;
    }
    /* For bigger than hd */
    @media (min-width: ${() => layout.breakpoint_hd}px) {
        .p1 {
            font-size: 24px;
        }
    }
    /* For hd */
    @media (max-width: ${() => layout.breakpoint_hd}px) {
        .p1 {
            font-size: 1.4vw;
        }
    }
    /* For desktop */
    @media (max-width: ${() => layout.breakpoint_desktop}px) {
        padding: 50px 40px;
        .p1 {
            font-size: 1.3vw;
        }
    }
    /* For tablet */
    @media (max-width: ${() => layout.breakpoint_tablet}px) {
        padding: 60px 50px;
        :nth-of-type(n) {
            margin-top: 0px;
        }
        height: 75vw;
        .h1 {
            font-size: calc(8.89vw - 11.1px);
        }
        .pic {
            width: calc(17.6vw - 22px);
            height: calc(17.6vw - 22px);
        }
        .p1 {
            font-size: 3.1vw;
        }
    }
    /* For phone */
    @media (max-width: ${() => layout.breakpoint_phone}px) {
        padding: 30px 25px;
        .h1 {
            font-size: calc(11.1vw - 13.3px);
        }
        .pic {
            width: calc(22vw - 26.6px);
            height: calc(22vw - 26.6px);
        }
    }
`;

class PhilosophyGridItem extends Component {
    render() {
        const { data, active } = this.props;
        return (
            <StyledPhilosophyGridItem data={data} active={active}>
                <div className="top">
                    <div className="Headlines">
                        <div className="h1">{data.title[0]}</div>
                        <div className="h1">{data.title[1]}</div>
                        <div className="h1">{data.title[2]}</div>
                    </div>
                    <div className="pic"></div>
                </div>
                <div className="paragraphs">
                    {data.body.map((p, index) => {
                        return (
                            <div key={index} className="p1">
                                {p}
                            </div>
                        );
                    })}
                </div>
            </StyledPhilosophyGridItem>
        );
    }
}

export default PhilosophyGridItem;
