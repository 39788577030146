import React, { Component } from "react";
import styled from "styled-components";
import { colors, layout, shadows } from "../style/base";
import Icon from "./Icon";
import { Link } from "react-router-dom";

// Images
import logoLight from "../assets/imgs/logotype-light.svg";
import logoDark from "../assets/imgs/logotype-dark.svg";

const StyledNavBar = styled.div`
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 22px 0 36px;

    .nav-bar-list {
        display: flex;
    }

    .nav-bar-list-entry {
        display: none;
    }

    .nav-bar-list-bg {
        display: flex;
    }

    .nav-bar-list-wrapper {
        display: flex;
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .nav-bar-list-item a {
        padding: 30px 24px;
        display: block;
        cursor: pointer;
    }

    .nav-bar-list-item.active {
        box-shadow: inset 0 -3px 0 ${(props) => (props.theme.mode === "dark" ? colors.dark.primaryText : colors.light.primaryText)};
    }

    .nav-bar-list-item a {
        color: ${(props) =>
            props.theme.mode === "dark"
                ? colors.dark.primaryText
                : colors.light.primaryText};
    }

    .logo a {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: ${(props) =>
            props.theme.mode === "dark"
                ? colors.dark.primaryText
                : colors.light.primaryText};
        padding: 22px 0;
    }

    .logo-mark {
        width: 36px;
        height: 36px;
        background-image: ${(props) =>
            props.theme.mode === "dark"
                ? `url(${logoDark})`
                : `url(${logoLight})`};
        background-repeat: no-repeat;
        background-size: contain;
        margin-right: 15px;
    }

    /* For phone */
    @media (max-width: ${() => layout.breakpoint_phone}px) {
        padding: 0 19px 0 16px;

        .nav-bar-list-entry {
            display: flex;
            cursor: pointer;
        }

        .nav-bar-list-wrapper {
            background-color: ${(props) =>
                props.theme.mode === "dark"
                    ? colors.dark.secondaryBackground
                    : colors.light.background};
            box-shadow: ${(props) =>
                props.theme.mode === "dark" ? shadows.d4 : shadows.l4};
            min-width: 200px;
            border-radius: 10px;
            padding: 16px 0;

            flex-direction: column;

            position: absolute;
            right: 30px;
            top: 68px;
        }

        .nav-bar-list-item a {
            padding: 20px 30px;
        }

        .nav-bar-list-item.active {
            box-shadow: inset 3px 0 0
                ${(props) =>
                    props.theme.mode === "dark"
                        ? colors.dark.primaryText
                        : colors.light.primaryText};
        }

        .nav-bar-list-bg {
            display: none;

            width: 100vw;
            height: 100vh;

            position: fixed;
            top: 0;
            left: 0;

            z-index: 100;
        }
    }
`;

class NavBar extends Component {
    componentDidMount() {
        this.setItemActive();
    }
    componentDidUpdate() {
        this.setItemActive();
    }

    render() {
        const listItems = ["Works", "About"];
        return (
            <StyledNavBar className="p2">
                <div className="logo">
                    <Link to="/">
                        <div className="logo-mark"></div>
                        <div className="logo-type">Bahtiyar Ahmidi</div>
                    </Link>
                </div>
                <div className="nav-bar-list">
                    <div onClick={this.showBg} className="nav-bar-list-entry">
                        <Icon name="menu" />
                    </div>
                    <div
                        onClick={this.onClickBg.bind(this)}
                        className="nav-bar-list-bg"
                    >
                        <ul className="nav-bar-list-wrapper">
                            {listItems.map((item, index) => {
                                return (
                                    <li
                                        key={index}
                                        className="nav-bar-list-item"
                                        onClick={this.hideBg}
                                    >
                                        <Link to={"/" + item.toLowerCase()}>
                                            {item}
                                        </Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </StyledNavBar>
        );
    }

    setItemActive() {
        const navBarItems = document.getElementsByClassName(
            "nav-bar-list-wrapper"
        )[0].children;
        const pathname = this.props.location.pathname
            .substring(1)
            .replace("/", "");

        for (let i = 0; i < navBarItems.length; i++) {
            const element = navBarItems[i];
            element.classList.remove("active");
            if (element.firstChild.innerHTML.toLowerCase() === pathname) {
                element.classList.add("active");
            }
        }
    }

    showBg() {
        const bg = document.getElementsByClassName("nav-bar-list-bg")[0];
        const body = document.getElementsByTagName("body")[0];
        body.classList.add("modal-open");
        bg.style.display = "flex";
    }

    hideBg() {
        const bg = document.getElementsByClassName("nav-bar-list-bg")[0];
        const body = document.getElementsByTagName("body")[0];
        body.classList.remove("modal-open");
        setTimeout(() => {
            bg.style.display = null;
        }, 138);
    }

    onClickBg(e) {
        if (e.target == e.currentTarget) {
            this.hideBg();
        }
    }
}

export default NavBar;
