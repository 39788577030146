import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

import Cover from "../components/Cover";
import WorkSection from "../components/WorkSection";
import WorkGrid from "../components/WorkGrid";
import Philosophy from "../components/Philosophy";
import CallToAction from "../components/CallToAction";
import ContactModal from "../components/ContactModal";
import Store from "../Store";

import works from "../assets/data/work_data";

const StyledHome = styled.div``;

const Home = () => {
    return (
        <StyledHome>
            <div className="contact-modal"></div>
            <Cover />
            <WorkSection />
            <WorkGrid workGridData={getWorkGridData()} />
            <Philosophy />
            <CallToAction />
        </StyledHome>
    );
};

const getWorkGridData = () => {
    return works.filter((work) => {
        if (work.position !== 1) {
            return false;
        }
        return true;
    });
};

export function showContactModal() {
    document.getElementsByTagName("body")[0].classList.add("modal-open");
    ReactDOM.render(
        <Store>
            <ContactModal />
        </Store>,
        document.querySelector(".contact-modal")
    );
}

export default Home;
